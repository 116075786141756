import {
  Hero,
  OgImage,
  Building,
  Defining,
  Deployment,
  Designig,
  Maintanance,
  Planing,
  Satisfied,
  Testing,
  Agile,
  Cross,
  Custom,
  Support,
  Team,
  Hospitality,
  ECommerceApp,
  EnterpriseApp,
  HybridApplications,
  NativeApp,
  OnDemandApp,
} from "../../images/Services/Mobile"


import {
  Flutter,
  ReactNative,
  Ios,
  Android,
  Aws,
  Healthcare,
  Logistics,
  Retail,
  Education,
} from "../../images/Services/WebTechnologies/"

const dataForMobile = {
  ogImage: OgImage,
  service: "mobile",
  hero: {
    title: "Mobile App Development Services",
    subtitle: "Boost your businesses with a modern mobile app.",
    description: "Let`s bring your business to a new level together!",
    img: Hero
  },
  development: {
    title: "Our mobile development services",
    description: "We support businesses with robust cloud applications and taking them to the next level. Our technical expertise is backed up by solid experience from complex cloud Our strong cloud expertise will help you implement cloud services whether you are planning to launch a new product or empower the already existing one.",
    cases: [
      {
        title: "Native Apps",
        description: "Our mobile apps development service delivers fully optimized iOS and Android solutions. Native apps provide the highest performance, fastest load time, and tight integration with hardware components such as cameras, GPS, and sensors.",
        img: NativeApp,
      },
      {
        title: "Hybrid Applications",
        description: "Our hybrid applications combine the power of web technologies with the performance of native apps. They are cost-effective and can run on multiple platforms using a single codebase, hence ideal for businesses willing to reach the masses.",
        img: HybridApplications,
      },
      {
        title: "Enterprise Apps",
        description: "We design enterprise mobile applications that streamline the alignment of internal business processes. Such applications may enhance communication, automate workflows, and grant employees secure access to company resources on the go.",
        img: EnterpriseApp,
      },
      {
        title: "eCommerce Apps",
        description: "We develop robust e-commerce applications with smooth and secure shopping experiences. Applications include integrated payment gateways, inventory management, and user-friendly interfaces to ensure a surge in sales and customer interaction.",
        img: ECommerceApp,
      },
      {
        title: "On-Demand Apps",
        description: "Our team develops on-demand mobile apps that help users connect to services in real time. From ride-hailing to food delivery, such apps are designed to be helpful and reliable for both enterprises and consumers.",
        img: OnDemandApp,
      },
    ]
  },
  solutions: {
    title: "Custom mobile app development solutions for your industry",
    description: "Our custom mobile application development services cater to a wide range of industries. We design and develop creative user-centric applications that resolve specific challenges, heighten productivity, and improve the overall customer experience.",
    cases: [
      {
        title: "Healthcare",
        description: "We design and create mobile healthcare applications that will serve to enhance patient care and simplify the administrative burden. From telemedicine platforms to patient monitoring systems, our apps make sure the data management is fully secure and communication between patients and healthcare providers is possible in real-time.",
        img: Healthcare,
      },
      {
        title: "Hospitality",
        description: "Our hospitality applications offer all services, from bookings online to concierge service, to enhance the guest experience. Be it for hotels or restaurants, they come equipped with smooth reservation systems, real-time customer feedback, and personalized services. Our mobile development services help increase customer satisfaction and operational efficiency.",
        img: Hospitality,
      },
      {
        title: "Retail",
        description: "Our e-commerce mobile applications focus on customer engagement. This results in better conversions and increased sales. Personalized recommendations, mobile payments, and real-time inventory updates are the features that help you to offer frictionless shopping experiences. With our team, you can easily cultivate customer loyalty and drive conversions.",
        img: Retail,
      },
      {
        title: "Education",
        description: "We develop interactive mobile applications for educational sectors, from e-learning to student management systems. These apps make learning accessible and appealing. With them, students and educators alike are facilitated by the option to communicate with peers and instructors, share content, and track the progress of study material.",
        img: Education,
      },
      {
        title: "Logistics",
        description: "We design mobile applications for logistic operations, which start from the issue of inventory and go to real-time tracking. These applications improve the efficiency of a supply chain by equipping a business with the capability to track shipments and optimize routes in real time.",
        img: Logistics,
      }
    ],
  },
  choose: {
    title: "Why you should choose us",
    description: "Selecting the appropriate custom mobile app development company makes all the difference in any project's success. Our experts apply innovation and focus on client needs in each application we develop.",
    cases: [
      {
        title: "Professional Team",
        description: "Our team comprises professional developers, designers, and project managers with extensive experience in mobile application development across diverse industries. We make sure that at every step, your project is in the hands of professionals who understand your business needs and technical requirements.",
        img: Team,
      },
      {
        title: "Custom Solutions",
        description: "Each app we develop is handcrafted to meet your business's particular goals and challenges. Everything must fit perfectly into your vision and meet the peculiar demands of your business.",
        img: Custom,
      },
      {
        title: "Cross-Platform Expertise",
        description: "We build iOS, Android, cross-platform, and hybrid apps. We ensure your application reaches targeted audiences while smoothly working on any device and operating system.",
        img: Cross,
      },
      {
        title: "Agile Development Process",
        description: "Agile development methodologies ensure your app is delivered on time and within budget. Continuous updates, testing, and feedback loops maintain our flexibility to adapt to changes in the project scope.",
        img: Agile,
      },
      {
        title: "Post-Launch Support",
        description: "Our apps development services do not end when the app is deployed. We support and maintain your app so that it keeps functioning smoothly. We can help you optimize your app based on user feedback and market trends.",
        img: Support,
      },
    ],
  },
  keys: {
    title: "Key Steps for Your Mobile App",
    description: "Our mobile app development design process follows a professional and structured approach that contains all the ingredients to ensure success. From initial planning to post-launch support, every step is thoughtfully considered to meet your business objectives and deliver a high-quality product.",
    cases: [
      {
        title: "Planing",
        description: "During this stage, we collaborate with you to pinpoint the intent of your app, its target audience, and your business needs. This entails in-depth discussions and studies to ensure that we understand how to successfully deliver the project.",
        number: "01",
        img: Planing,
      },
      {
        title: "Designing",
        description: "Our design team creates intuitive designs for user interface and user experiences that are aligned with your brand and enhance usability. We focus on delivering seamless and engaging experiences for users of your app across various device types.",
        number: "02",
        img: Designig,
      },
      {
        title: "Defining",
        description: "It involves defining the technical requirements, functionalities, and features that your app will have. This involves selecting appropriate technologies, frameworks, and development tools that will ensure the solution is scalable, secure, and effective.",
        number: "03",
        img: Defining,
      },
      {
        title: "Building",
        description: "Our development team begins the actual coding of your app upon a defined specification. Here is where your idea takes flesh and blood: we implement every component, carefully testing functionality and performance across all targeted platforms.",
        number: "04",
        img: Building,
      },
      {
        title: "Testing",
        description: "We do testing for bug fixes before the app goes live. It includes functionality testing, performance testing, and user acceptance tests. Everything must work well and according to quality standards. This is our priority.",
        number: "05",
        img: Testing,
      },
      {
        title: "Deployment",
        description: "Once the app has been fully tested and approved, we deploy it. Deployment includes preparing and submitting your application in app stores such as iOS and Android, with seamless release to your target audience.",
        number: "06",
        img: Deployment,
      },
      {
        title: "Maintenance",
        description: "After deployment, our mobile app development services don't end. We maintain and support your app after its launch so that it keeps on working seamlessly. Updates, bug fixing, optimization, received feedback from users, and new technology trends are handled by us.",
        number: "07",
        img: Maintanance,
      },
      {
        title: "Satisfied client",
        description: "A satisfied client is key to app success. We focus on user feedback, timely updates, and ongoing support to ensure your app meets market needs and aligns with your business objectives.",
        number: "08",
        img: Satisfied,
      },
    ]
  },
  technologies: {
    title: "Tech Stack That We Use",
    techData: {
      tools: [ReactNative, Flutter, Android, Ios, Aws]
    },
  },
}

export default dataForMobile
