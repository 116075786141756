import React from "react";
import './Development.scss';

const CaseComponent = ({ data }) => {
  const { title, description, img, imgMin } = data;
  return (
    <div className="md-case">
      <div className="title-container">
        <div className="img-container">
          <img src={img} alt="web development icon" />
        </div>
        <h3 className="title">{title}</h3>
      </div>
      <div className="description">{description}</div>
    </div>
  );
};

export default function Development({ data }) {
  const { title, description, cases } = data;
  return (
    <section className="md-wrapper">
      <div className="md-text">
        <h2 className="md-title">{title}</h2>
        <div className="md-description">{description}</div>
      </div>
      <div className="md-cases">
        {cases.map((caseData) => <CaseComponent data={caseData} />)}
      </div>
    </section>
  );
};
