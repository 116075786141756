import React from "react";

import './Keys.scss';

const CaseComponent = ({ data }) => {
  const { title, description, number, img } = data;
  return (
    <div className="keys-case">
      <div className="img-container">
          <img src={img} alt="web development icon" />
      </div>
      <div className="title-wrapper">
        <div className="title-container">
          <div className="number">{number}</div>
          <div className="title">{title}</div>
        </div>
      </div>
      <div className="description">{description}</div>
    </div>
  );
}

export default function Keys({ data }) {
  const { title, description, cases } = data;
  return (
    <div className="keys-wrapper">
      <div className="keys-text">
        <h2 className="keys-title">{title}</h2>
        <div className="keys-description">{description}</div>
      </div>
      <div className="keys-cases">
        {cases.map((caseData) => <CaseComponent data={caseData} />)}
      </div>
    </div>
  );
};
