import React from "react";
import { Link } from "react-scroll"
import { Link as GatsbyLink } from "gatsby";   

import './Hero.scss'

export default function Hero({
  data,
  portfolioBtn=false
}) {
  return (
    <section className="hero-parent">
      <div className="hero-child">
        <div className="hero-text-container">
          <h1 className="hero-title">{data.title}</h1>
          <h2 className="hero-subtitle">{data.subtitle}</h2>
          <div className="hero-description">{data.description}</div>
          <div className="hero-btns-container">
            <Link
                to="contact-us-form"
                spy={true}
                smooth={"easeOutSine"}
                offset={-40}
                duration={1000}
              >
              <button className="hero-contact-btn">
                Contact us
              </button>
            </Link>
            {portfolioBtn && (
              <GatsbyLink to={"/cases/"}>
                <button className="hero-portfolio-btn">
                  Our Portfolio
                </button>
              </GatsbyLink>
            )}
          </div>
        </div>
        <div className="hero-img-container">
          <img className="hero-img" src={data.img} alt="common image" />
        </div>
      </div>
    </section>
  );
};

