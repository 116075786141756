import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import Hero from "../../components/SingleService/Common/Hero"
import Development from "../../components/SingleService/Mobile/Development"
import Cases from "../../components/CommonComponents/Cases"
import Technologies from "../../components/SingleService/Common/Technologies"
import Keys from "../../components/SingleService/Common/Keys"
import Solutions from "../../components/SingleService/Common/Solutions"
import Choose from "../../components/SingleService/Common/Choose"
import Experience from "../../components/SingleService/Common/Experience"
import ContactUsExpertise from "../../components/CommonComponents/ContactUsExpertise"
import dataForMobile from "../../information/Services/dataForMobile"
import { mainUrl } from "../../js/config"
import dataForProjects from "../../information/CloudSolutions/dataForProjects.json"

const settings = {
  dots: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: "center",
      },
    },
  ],
}

export default function Mobile() {
  const sendUrl = typeof window !== "undefined" ? window?.location?.href : "";
  const { hero, development, solutions, choose, keys, technologies, ogImage } = dataForMobile
  return (
    <section>
      <SEO
        title="Custom Mobile App Development Services | OS-System"
        description="OS-System is #1 in terms of mobile app development services. We create apps for healthcare, retail, logistics, hospitality, and other industries. If you need iOS, Android, cross-chain, or hybrid apps, feel free to contact us."
        canonical={`${mainUrl}/services/mobile/`}
        ogImage={ogImage}
      />
      <Layout showFormPoint={50}>
        <Hero data={hero} />
        <Development data={development} />
        <Experience />
        <Solutions data={solutions} />
        <Choose data={choose} />
        <Keys data={keys} />
        <Technologies data={technologies} isCompact={true} />
        <Cases
          projects={dataForProjects}
          settings={settings}
          headerText="Work Projects"
          customClass="cloud"
        />
        <ContactUsExpertise form={0} url={sendUrl} />
      </Layout>
    </section>
  )
}
